import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";

let possibleSliders = [
	".js-slider-offer",
	".js-slider-offer-2",
];
(async () => {
	if (possibleSliders.some((x) => document.querySelector(x))) {
		let Swiper = await import("swiper");
		Swiper = Swiper.default
		Swiper.use([Navigation, Pagination, EffectFade, Autoplay])

		new Swiper(".js-slider-offer .swiper-container", {
			speed: 1000,
			navigation: {
				nextEl: '.js-slider-offer .js-swiper-nav-next',
				prevEl: '.js-slider-offer .js-swiper-nav-prev',
			},
			slidesPerView: 1,
			spaceBetween: 4,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 3,
					simulateTouch: false
				},
			},
			pagination: {
				el: '.js-slider-offer .js-swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
		});

		new Swiper(".js-slider-offer-2 .swiper-container", {
			speed: 1000,
			navigation: {
				nextEl: '.js-slider-offer-2 .js-swiper-nav-next',
				prevEl: '.js-slider-offer-2 .js-swiper-nav-prev',
			},
			slidesPerView: 1,
			spaceBetween: 4,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 3,
					simulateTouch: false
				},
			},
			pagination: {
				el: '.js-slider-offer-2 .js-swiper-pagination',
				type: 'bullets',
			},
		});
	}
})();
