import { 	curtainOpenerToggle, curtainMenuOpenerToggle } from './anime'
/** Togglers in header */
;(() => {
	const headerDOM = document.querySelector('.js-site-header')
	const bodyDOM = document.querySelector('body')
	const burgerDOM = document.querySelector('.js-burger-menu')
	window.menuToggler = function(flag){
		headerDOM.classList.toggle('is-menu-toggled')
		burgerDOM.classList.toggle('is-active')
		bodyDOM.classList.toggle('overflow-hidden')
		
		curtainOpenerToggle();
		if(flag) setTimeout(() => curtainMenuOpenerToggle(), 500); else curtainMenuOpenerToggle();
	}
})();

;(() => {
	window.changeListItem = function(list, index){
		const listItems = document.querySelectorAll(list)
		listItems.forEach(x => x.classList.remove('is-active'))
		listItems[index].classList.add('is-active')
	}
})();
