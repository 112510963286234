import anime from 'animejs';

const curtainOpenerTimeline = anime.timeline({
	autoplay: false,
});


curtainOpenerTimeline
	.add({
		targets: '.js-mobile-menu',
		duration: 400,
		translateX: ["100%", 0],
		easing: 'easeInCubic'
	});



const curtainMenuOpenerTimeline = anime.timeline({autoplay: false})
curtainMenuOpenerTimeline
	.add({
		targets: '.nav > .menu-item',
		duration: 800,
		translateX: ["-300px", 0],
		opacity: [0, 1],
		// easing: 'easeInCubic',
		delay: function(el, i, l) {
			return i * 100;
		},
	})


const toggleTimeline = (timeline) => {
	if (timeline.began) {
		timeline.reverse()
		
		if (timeline.progress === 100 && timeline.direction === 'reverse') {
			timeline.completed = false
		}
	}

	if (timeline.paused) {
		timeline.play()
	}
}

const curtainOpenerToggle = () => toggleTimeline(curtainOpenerTimeline)
const curtainMenuOpenerToggle = () => toggleTimeline(curtainMenuOpenerTimeline)
export { 
	curtainOpenerToggle,
	curtainMenuOpenerToggle
}


// const seconda = anime.timeline({autoplay:false, delay: 1200});
// seconda.add({
// 	targets: '.js-header-desktop',
// 	duration: 1500,
// 	opacity: [0, 1],
// 	translateY: ["-100%", 0],
// 	easing: 'easeOutExpo'
// })
// .add({
// 	targets: '.js-frontpage-social-media',
// 	duration: 2000,
// 	opacity: [0, 1],
// 	translateY: ["100%", 0],
// 	easing: 'easeOutExpo'
// }, "-=3000");


/**
 * Animate curtain before leaving page
 */
window.beforeLeave = function (event, target) {
	if(target.getAttribute('target') && target.getAttribute('target') == "_blank") return;
	
	event.preventDefault();
	curtainOpenerTimeline.reverse();
	curtainOpenerTimeline.play();

	setTimeout(() => {
		window.location.href = target.getAttribute("href");
	}, 300)
}

const menuItems = document.querySelectorAll('.js-header-desktop a');
menuItems.forEach(el => {
	el.addEventListener('click', (event) => {
		beforeLeave(event, el);
	})
});





const menuHasChildrenDOM = document.querySelectorAll('.menu-item-has-children')
const mobileNavContainer = document.querySelector('.js-mobile-nav-container')
const mobileMenuBack = document.querySelector('.js-mobile-menu-back')
const mobileSubMenuContainer = document.querySelector('.js-mobile-sub-menu-container')

const mobileNavContainerTimeline = anime.timeline({autoplay: false});
mobileNavContainerTimeline.add({
	targets: mobileNavContainer,
	duration: 800,
	translateX: [0, "-300px"],
	opacity: [1, 0],
	easing: 'easeInQuad'
})

const mobileSubMenuContainerTimeline = anime.timeline({autoplay: false});
mobileSubMenuContainerTimeline.add({
	targets: mobileSubMenuContainer,
	duration: 800,
	translateX: ["100%", 0],
	opacity: [0, 1],
	easing: 'easeInQuad'
})

menuHasChildrenDOM.forEach(x => {
	const container = document.querySelector('.js-sub-menu-holder')
	const subMenu = x.querySelector('.sub-menu')
	const toggler = x.querySelector('.js-menu-item-arrow')

	const clone = subMenu.cloneNode(true)
	// container.removeChild
	toggler.addEventListener('click', () => {
		toggleTimeline(mobileNavContainerTimeline);
		toggleTimeline(mobileSubMenuContainerTimeline);
		// open
		if(!toggler.classList.contains('is-active')){
			container.replaceChildren(clone);
			toggler.classList.add('is-active')
		}else{
			// container.replaceChildren();
			toggler.classList.remove('is-active')
		}
	})
})
mobileMenuBack.addEventListener('click', () => {
	toggleTimeline(mobileNavContainerTimeline);
	toggleTimeline(mobileSubMenuContainerTimeline);
})