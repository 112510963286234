import fetchWrapper from "../../modules/fetchWrapper";

export default (Vue) => {
	const vContactForm = document.querySelector('.vue-contact-form');
	if(!vContactForm) return;

	Vue.component('v-contact-form', {
		name: 'v-contact-form',
		template: vContactForm.innerHTML,
		props: ['formId'],
		data(){
			return {
				formData: {},
				fetchState: {
					isLoading: false,
					errors: {},
					message: ''
				}
			}
		},
		methods: {
			async submit(){
				const [status, result] = await fetchWrapper({
					url: `/wp-json/contact-form-7/v1/contact-forms/${this.$attrs.formid}/feedback`,
					method: "POST",
					body: this.formData
				});

				console.log(status, result)
				if(status > 300){
					this.fetchState.message = 'Coś poszło nie tak'
				}else{
					if(result.invalid_fields && result.invalid_fields.length > 0){
						this.fetchState.errors = {}
						result.invalid_fields.forEach(x => {
							this.fetchState.errors[x.error_id.replace('-ve-', '')] = x.message
						})
					}else if (result.status == 'mail_sent'){
						this.fetchState.message = result.message
					}
				}
			}
		},
	})
}