import "../styles/main.scss";

console.log('%c Streamline', 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38)');

import "./use-cases/slider";
import "./use-cases/interacts";
import "./use-cases/onscroll";
// import "./use-cases/modal";
// import "./use-cases/forms";
import "./use-cases/accordion";
import "./use-cases/lightbox";
// import "./use-cases/count-up";
import "./use-cases/anime";
// import "./use-cases/charts";
import "./use-cases/menu"
import "./vue/index.js";




const headingsDOM = document.querySelectorAll('.js-heading')
headingsDOM.forEach(x => {
	let content = x.textContent
		.trim()
		.split(" ")
		.map((word,i) => i===0 ? `<span class="heading-decoration-wrapper"><span class="heading-decoration"></span>${word}</span>` : word);
	x.innerHTML = content.join(" ")
})